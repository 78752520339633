import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class SettingService {
    
    constructor(private http: HttpClient) { }

    getSettingData() {
        return this.http.get('/api/get-setting-data');
    }
    settingUpdate(data){
    	return this.http.post('/api/setting-data-update',data);
    }

    
}