import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Injectable()
export class DashboardService {

  constructor(private http:HttpClient) { }

  totalCount(){
    return  this.http.get('/api/get-all-total-count');
  }

  getChartData(){
    return  this.http.get('/api/get-chart-data');
  }

  sumAllData(){
    return this.http.get('/api/get-all-dashboard-data');
  }

  getLastProduct(){
    return this.http.get('/api/get-latestProduct');
  }
}
