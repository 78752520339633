import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
// import { HttpModule } from '@angular/http';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { DataTablesModule } from 'angular-datatables';

import { LocationStrategy, HashLocationStrategy } from '@angular/common';

import { AppComponent } from './app.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
// import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
// import { TabsModule } from 'ngx-bootstrap/tabs';
import { NAV_DROPDOWN_DIRECTIVES } from './components/shared/nav-dropdown.directive';

import { SIDEBAR_TOGGLE_DIRECTIVES } from './components/shared/sidebar.directive';
import { BreadcrumbsComponent } from './components/shared/breadcrumb.component';

// Routing Module
import { AppRoutingModule } from './app.routing';

// Layouts
import { FullLayoutComponent } from './layouts/full-layout.component';
import { AlertComponent } from './components/alert/index';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CustomerComponent } from './customer/customer.component';
import { UserComponent } from './user/user.component';
import { UserRoleComponent } from './user/user-role/user-role.component';
import { CategoryComponent } from './category/category.component';
import { SubCategoryComponent } from './sub-category/sub-category.component';
import { ProductComponent } from './product/product.component';
import { DamagedProductComponent } from './damaged-product/damaged-product.component';
import { PurchasesComponent } from './purchases/purchases.component';
import { PurchasesHistoryComponent } from './purchases/purchases-history/purchases-history.component';
import { PurchasesDetailsComponent } from './purchases/purchases-details/purchases-details.component';
import { PurchasesDuePaymentComponent } from './purchases/purchases-due-payment/purchases-due-payment.component';
import { SalesComponent } from './sales/sales.component';
import { SalesHistoryComponent } from './sales/sales-history/sales-history.component';
import { InvoiceDetailsComponent } from './sales/invoice-details/invoice-details.component';
import { SalesTakePaymentComponent } from './sales/sales-take-payment/sales-take-payment.component';
import { SettingComponent } from './setting/setting.component';
import { ProfileSettingsComponent } from './profile-settings/profile-settings.component';
import { SupplierComponent}  from './supplier/supplier.component';
import { SalesReportComponent}  from './report/sales-report.component';
import { PurchasesReportComponent }   from './report/purchases-report/purchases-report.component';

import { fakeBackendProvider,JwtInterceptor,ErrorInterceptor } from './_helpers';
import { ChartsModule } from 'ng2-charts';
import { AuthGuard } from './_guards/index';

import { NgSelectModule } from '@ng-select/ng-select';
import { RichTextEditorAllModule, ToolbarService, LinkService, ImageService, HtmlEditorService, TableService } from '@syncfusion/ej2-angular-richtexteditor';

import { NotificationComponent } from './notification/notification.component';
import { TextEditorComponent } from './components/text-editor/text-editor.component';

import { 
  AlertService,
  AuthenticationService, 
  AppService,
  SettingService,
  DashboardService,
  CategoryService,
  CustomerService,
  DamagedProductService,
  PurchasesHistoryService,
  SalesHistoryService,
  SupplierService,
  InvoiceDetailsService,
  UserRoleService,
  UserService,
  ProductService,
  PurchaseReportService,
  PurchaseService,
  PurchasesDetailsService,
  PurchasesDuePaymentService,
  SalesService,
  SalesTakePaymentService,
  SubCategoryService,
  ReportService,
  NotificationService
} from './_services/index';


@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    // BsDropdownModule.forRoot(),
    // TabsModule.forRoot(),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ChartsModule,
    DataTablesModule,
    RichTextEditorAllModule,
    NgSelectModule,
    NgSelectModule
  ],
  declarations: [
    AppComponent,
    FullLayoutComponent,
    AlertComponent,
    LoginComponent,
    DashboardComponent,
    CustomerComponent,
    UserComponent,
    UserRoleComponent,
    CategoryComponent,
    SubCategoryComponent,
    ProductComponent,
    DamagedProductComponent,
    PurchasesComponent,
    PurchasesDetailsComponent,
    PurchasesDuePaymentComponent,
    PurchasesHistoryComponent,
    PurchasesReportComponent,
    SalesComponent,
    SalesHistoryComponent,
    SalesReportComponent,
    SalesTakePaymentComponent,
    InvoiceDetailsComponent,
    SettingComponent,
    ProfileSettingsComponent,
    SupplierComponent,
    NAV_DROPDOWN_DIRECTIVES,
    BreadcrumbsComponent,
    SIDEBAR_TOGGLE_DIRECTIVES,
    NotificationComponent,
    TextEditorComponent
  ],
  providers: [
    AlertService,
    AuthenticationService, 
    AppService,
    CategoryService,
    CustomerService,
    DashboardService,
    DamagedProductService,
    ProductService,
    PurchaseService,
    PurchasesHistoryService,
    PurchasesDetailsService,
    PurchasesDuePaymentService,
    PurchaseReportService,
    ReportService,
    SalesService,
    SalesHistoryService,
    SalesTakePaymentService,
    SettingService,
    SubCategoryService,
    SupplierService,
    InvoiceDetailsService,
    UserRoleService,
    UserService,
    ToolbarService, 
    LinkService, 
    ImageService, 
    HtmlEditorService, 
    TableService,
    NotificationService,
    /*{
      provide: LocationStrategy,
      useClass: HashLocationStrategy,
    },*/

    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
 
        // provider used to create fake backend
        fakeBackendProvider
    /* AppService,
    AuthGuard,
    AlertService,
    AuthenticationService,
    SettingService */
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
