import { Component, OnInit } from '@angular/core';
import { AppService, AuthenticationService} from '../_services/index';

@Component({
  selector: 'app-dashboard',
  templateUrl: './full-layout.component.html'
})
export class FullLayoutComponent implements OnInit {
  userData = {
    profileName:'',
    image:''
  }
  getToken:any;
  showDNClass: boolean = false;
  setting = {image:''};
  constructor(public authenticationService: AuthenticationService,private dataService:AppService) { }
  nav:any;

  public disabled = false;
  public status: {isopen: boolean} = {isopen: false};

  public toggled(open: boolean): void {
    console.log('Dropdown is now: ', open);
  }

  public toggleDropdown($event: MouseEvent): void {
    $event.preventDefault();
    $event.stopPropagation();
    this.status.isopen = !this.status.isopen;
  }

  ngOnInit(): void {
    setTimeout(() => {
        this.showDNClass = true;
    },20);

    this.getToken = window.localStorage.getItem('currentUser');
      this.dataService.checkLogin(this.getToken)
          .pipe().subscribe(data => {
                if(data['user']){
                  this.userData = {
                      profileName:data['user'].name,
                      image:data['user'].image,
                  }
                  this.dataService.getMenu()
                    .pipe().subscribe(data => { 
                      this.nav = data['data'];
                    });
                  }
            });
            let settingData = JSON.parse(localStorage.getItem('setting'));
            this.setting = {image:settingData.image};

        
  }

  public logout(): void{
    // remove user from local storage to log user out
    this.authenticationService.logout();
  }
}