import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DashboardService,SettingService, AlertService } from '../_services/index';

@Component({
  templateUrl: 'dashboard.component.html'
})
export class DashboardComponent implements OnInit {

  public barChartOptions:any = {
    scaleShowVerticalLines: false,
    responsive: true
  };
  barChartLabels:any[] = [];
  public barChartType:string = 'bar';
  public barChartLegend:boolean = true;

  // dropdown buttons
  public status: { isopen } = { isopen: false };
  public toggleDropdown($event: MouseEvent): void {
    $event.preventDefault();
    $event.stopPropagation();
    this.status.isopen = !this.status.isopen;
  }

  data: any;
  customerPayment = {};
  sales:any;
  payment:any;
  expense:any;
  setting = {currency:''};
  totalCount = {
    product:'',
    damaged_product:'',
    customer:'',
    supplier:'',
    user:'',
    category:''
  }

  lastProduct:any;
  lastOrder:any;
  showloding = true;
  lodingImage = false;
  constructor(
              private dataService:DashboardService,
              private settingService:SettingService,
              private alertService: AlertService,
            ) { }

  barChartData:any=[];
  ngOnInit() {
    this.barChartData = [
        {data: [0], label: 'Purchases'},
        {data: [0], label: 'Sales'}
      ];
    
    this.loadShow();
    this.dataService.getChartData()
        .pipe().subscribe(data => {
              // let dateArray = []; 
              let salesAmountArray = []; 
              let sales = data['sales']; 
                  for (let i = 0; i < sales.length; ++i) {
                    this.barChartLabels.push(sales[i].date);
                    salesAmountArray.push(sales[i].amount);
                  }
              let purchaseAmountArray = []; 
              let purchase = data['purchase'];
                  for (let i = 0; i < purchase.length; ++i) {
                    purchaseAmountArray.push(purchase[i].amount);
                  }

                  this.barChartData = [
                    {data: purchaseAmountArray, label: 'Purchases'},
                    {data: salesAmountArray, label: 'Sales'}
                  ];
              this.loadHide();
        });


      this.dataService.totalCount()
          .pipe().subscribe(data => { 
              this.totalCount ={
                product:data['product'].count,
                damaged_product:data['damaged_product'].count,
                supplier:data['supplier'].count,
                user:data['user'].count,
                customer:data['customer'].count,
                category:data['category'].count
              } 
          });


      this.dataService.sumAllData()
          .pipe().subscribe(data => { 
            this.sales = data['sales'].total;
            this.payment = data['payment'].total;
            this.expense = data['expense'].total;
          });

      this.dataService.getLastProduct()
          .subscribe(data => { 
            this.lastProduct = data['product'];
          });

      let settingData = JSON.parse(localStorage.getItem('setting'));
      this.setting = {currency:settingData.currency};
      
  }

  loadShow(){
    this.showloding = true;
    this.lodingImage = false;
  }

  loadHide(){
      this.showloding = false;
    this.lodingImage = true;
  }
  
}
